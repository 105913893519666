import { forwardRef, ReactNode, ForwardedRef } from 'react';

const SVG = ({
  height,
  width,
  shouldRender,
  children
}: {
  height: number,
  width: number,
  shouldRender?: boolean,
  children: ReactNode
}, ref: ForwardedRef<SVGSVGElement>) => {
  return shouldRender ? (
    <svg
      height={height}
      width={width}
      viewBox={`0 0 ${width} ${height}`}
      ref={ref}
    >
      {children}
    </svg>
  ) : (
    <div style={{height}} />
  );
};

export default forwardRef(SVG);

import type { ReactNode } from 'react';
import type { FallbackProps } from 'react-error-boundary';

const ErrorFallback = (componentName?: string) => ({ error, resetErrorBoundary }: FallbackProps): ReactNode => {
  return (
    <div>
      <p>Something went wrong with <code>{componentName || "a component"}</code>:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export default ErrorFallback;

import { useState, useEffect } from 'react';
import getApplications, { Application } from 'data/get-applications';
import {TAirtableQueryResults} from 'types/Airtable';


const useApplications = (override = false) => {
  const [applications, setApplications] = useState<Application[]>([]);

  useEffect(() => {
    const fetchApplications = async () => {
      await getApplications().then((response: TAirtableQueryResults) => {
        setApplications(response.data);
      });
    }
    if (!applications.length || override) {
      fetchApplications();
    }
  }, [applications, override]);

  return applications;
};

export {
  useApplications
};

import {
  red,
  pink,
  purple,
  deepPurple,
  // indigo,
  // blue,
  lightBlue,
  // cyan,
  teal,
  green,
  lightGreen,
  // lime,
  // yellow,
  // amber,
  orange,
  // deepOrange,
  brown,
  // grey,
  blueGrey
} from '@mui/material/colors';
import { APPLICATION_STATUS } from 'types/ApplicationStatus';

const STATUS_COLORS = {
  [APPLICATION_STATUS.Me]: lightBlue[700],
  [APPLICATION_STATUS.Recruiter]: purple[400],
  [APPLICATION_STATUS.Applied]: orange[800],
  [APPLICATION_STATUS.Rejected]: red[500],
  [APPLICATION_STATUS.Waiting]: blueGrey[600],
  [APPLICATION_STATUS.MgrTeam]: lightGreen[700],
  [APPLICATION_STATUS.Initial]: deepPurple[400],
  [APPLICATION_STATUS.Technical]: pink[400],
  [APPLICATION_STATUS.Code]: pink[400],
  [APPLICATION_STATUS.Offer]: green[700],
  [APPLICATION_STATUS.Interviews]: pink[400],
  [APPLICATION_STATUS.Accepted]: teal[700],
  [APPLICATION_STATUS.Declined]: brown[600],
}

const getColorForLabel = (label: APPLICATION_STATUS) => STATUS_COLORS?.[label];

export {
  getColorForLabel
};

import React, { useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import SVG from 'components/svg';
import type { Application } from 'data/get-applications';
import { useApplications } from 'hooks/use-applications';
import { APPLICATION_STATUS, APPLICATION_STATUS_LABELS, Source } from 'types/ApplicationStatus';
import { getColorForLabel } from 'utils/color';

type StatusWithSource = {
  status: APPLICATION_STATUS,
  me: number
  recruiter: number
}

const CHART_WIDTH = 800;
const CHART_HEIGHT = 450;

enum margin {
  'Top' = 16,
  'Right' = 16,
  'Bottom' = 32,
  'Left' = 32,
}

const statusesToShow = [
  APPLICATION_STATUS.Initial,
  APPLICATION_STATUS.MgrTeam,
  APPLICATION_STATUS.Interviews,
  APPLICATION_STATUS.Offer,
  APPLICATION_STATUS.Accepted,
  APPLICATION_STATUS.Rejected,
];

const gradientId = (start: APPLICATION_STATUS, end?: APPLICATION_STATUS): string => {
  return [start, end ?? 'end'].map((str) => str.toLowerCase().replaceAll(' ', '_')).join('-');
}

const Gradient = ({start, end}: {start: APPLICATION_STATUS, end: APPLICATION_STATUS}) => {
  return (
    <linearGradient id={gradientId(start, end)} x1="0%" x2="0%" y1="100%" y2="0%" >
      <stop offset="15%" stopColor={getColorForLabel(start)} />
      <stop offset="100%" stopColor={getColorForLabel(end)} />
    </linearGradient>
  )
}

const SourceBar = ({
  label,
  width,
  x,
  qty,
  status,
  yScale
}: {
  label: Source,
  width: number,
  x: number,
  qty: number,
  status: APPLICATION_STATUS,
  yScale: d3.ScaleLinear<number, number, never>
}) => {
  const baseline = CHART_HEIGHT - (margin.Top + margin.Bottom);
  const h = useMemo(() => CHART_HEIGHT - margin.Bottom - yScale(qty), [qty, yScale]);
  const y = baseline - h;
  const title = `${qty} applications from ${label} resulted in ${APPLICATION_STATUS_LABELS?.[status] || status}`;
  const fillId = `url(#${gradientId(label, status)})`;

  return (
    <g>
      <title>{title}</title>
      <rect x={x} y={y} width={width / 2} height={h} fill={fillId ?? getColorForLabel(label as APPLICATION_STATUS)} />
      <text x={x + width / 4} y={baseline - 3} textAnchor="middle">
        {qty}
      </text>
    </g>
  )
}

const SourceBars = ({
  d,
  width,
  x,
  yScale
}: {
  d: StatusWithSource,
  width: number,
  x: number,
  yScale: d3.ScaleLinear<number, number, never>
}) => {
  return (
    <g>
      <SourceBar status={d.status} label={APPLICATION_STATUS.Me} width={width} x={x} qty={d.me} yScale={yScale} />
      <SourceBar status={d.status} label={APPLICATION_STATUS.Recruiter} width={width} x={x + width / 2} qty={d.recruiter} yScale={yScale} />
    </g>
  );
}

const ApplicationSourceStatus = () => {
  const applications: Application[] = useApplications();
  const chartRef = useRef<SVGSVGElement>(null);

  const statusesWithSource = useMemo(() => {
    if (!applications) return [];

    const data: StatusWithSource[] = Array.from(statusesToShow, s => ({
      status: s,
      me: 0,
      recruiter: 0
    }));

    const updateStatus = (status: APPLICATION_STATUS, source: Source) => {
      const dataStatus = data.find(d => d.status === status);
      if (!dataStatus) return;

      if (source === APPLICATION_STATUS.Me) {
        dataStatus.me++;
      } else if (source === APPLICATION_STATUS.Recruiter) {
        dataStatus.recruiter++;
      }
    }

    applications
      .filter((app: Application) => app?.[APPLICATION_STATUS.Applied])
      .forEach((app: Application) => {
        if (!app?.Origin) return;

        if (app?.[APPLICATION_STATUS.Initial]) {
          updateStatus(APPLICATION_STATUS.Initial, app.Origin as Source);
        }

        if (app?.[APPLICATION_STATUS.MgrTeam]) {
          updateStatus(APPLICATION_STATUS.MgrTeam, app.Origin as Source);
        }

        if (app?.[APPLICATION_STATUS.Technical] || app?.[APPLICATION_STATUS.Code]) {
          updateStatus(APPLICATION_STATUS.Interviews, app.Origin as Source);
        }

        if (app?.[APPLICATION_STATUS.Offer]) {
          updateStatus(APPLICATION_STATUS.Offer, app.Origin as Source);
        }

        if (app?.Result === APPLICATION_STATUS.Accepted) {
          updateStatus(APPLICATION_STATUS.Accepted, app.Origin as Source);
        }

        if (app?.Rejected || app?.Result === APPLICATION_STATUS.Rejected) {
          updateStatus(APPLICATION_STATUS.Rejected, app.Origin as Source);
        }
      });

    return data;
  }, [applications]);

  const maxValue = useMemo(() => {
    return statusesWithSource.reduce<number>((acc, s) => {
      acc = [acc, s.me, s.recruiter].sort((a, b) => a - b).pop() ?? 0;
      return Math.ceil(acc / 5) * 5;
    }, 0);
  }, [statusesWithSource]);

  const xScale = useMemo(() => {
    return d3.scaleBand()
      .domain(statusesWithSource.map(s => APPLICATION_STATUS_LABELS?.[s.status] || s.status))
      .range([margin.Left, CHART_WIDTH - margin.Right]);
  }, [statusesWithSource]);

  const yScale = useMemo(() => {
    return d3.scaleLinear()
      .domain([0, maxValue])
      .range([CHART_HEIGHT - margin.Bottom, margin.Top]);
  }, [maxValue]);

  const barWidth = ((CHART_WIDTH - margin.Left - margin.Right) / statusesToShow.length) - margin.Right;

  useEffect(() => {
    if (!chartRef?.current || !statusesWithSource.length) return;

    const svg = d3.select(chartRef.current);

    svg.append('g').attr('transform', `translate(0, ${CHART_HEIGHT - margin.Bottom})`).call(d3.axisBottom(xScale));
    svg.append('g').attr('transform', `translate(${margin.Left}, 0)`).call(d3.axisLeft(yScale));
  }, [xScale, yScale, statusesWithSource]);

  return (
   <>
    <h2>Application Status by Source</h2>
    <SVG ref={chartRef} width={CHART_WIDTH} height={CHART_HEIGHT} shouldRender={!!applications}>
      <defs>
        {statusesWithSource.map((d: StatusWithSource) => {
          return (<>
            <Gradient key={`ssm-${d.status}`} start={APPLICATION_STATUS.Me} end={d.status} />
            <Gradient key={`ssr-${d.status}`} start={APPLICATION_STATUS.Recruiter} end={d.status} />
          </>)
        })}
      </defs>
      <g className="data" transform={`translate(${margin.Right / 2}, ${margin.Top})`}>
        {statusesWithSource.map((d: StatusWithSource, idx) => (
          <SourceBars
            key={`ssb-${d.status}`}
            width={barWidth}
            d={d}
            x={xScale(APPLICATION_STATUS_LABELS?.[d.status] || d.status) ?? 0}
            yScale={yScale}
          />
        ))}
      </g>
    </SVG>
   </>
  );
};

export default ApplicationSourceStatus;

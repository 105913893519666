import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ApplicationsFlow from 'components/applications-flow';
import ApplicationsSent from 'components/applications-sent';
import ApplicationSourceStatus from 'components/application-source-status';
import ApplicationStatus from 'components/application-status';
import ApplicationsTiming from 'components/applications-timing'
import ErrorFallback from 'components/error-fallback';

const ApplicationDashboard = () => {
  return (
    <>
      <h1>Application Dashboard</h1>
      <ErrorBoundary fallbackRender={ErrorFallback('ApplicationsFlow')}>
        <ApplicationsFlow />
      </ErrorBoundary>
      <ErrorBoundary fallbackRender={ErrorFallback('ApplicationsSent')}>
        <ApplicationsSent />
      </ErrorBoundary>
      <ErrorBoundary fallbackRender={ErrorFallback('ApplicationsTiming')}>
        <ApplicationsTiming />
      </ErrorBoundary>
      <ErrorBoundary fallbackRender={ErrorFallback('ApplicationSourceStatus')}>
        <ApplicationSourceStatus />
      </ErrorBoundary>
      <ErrorBoundary fallbackRender={ErrorFallback('ApplicationStatus')}>
        <ApplicationStatus />
      </ErrorBoundary>
      </>
  )
}

export default ApplicationDashboard;

import React from 'react';
import ApplicationDashboard from 'views/application-dashboard';
import './App.css';

function App() {
  return (
    <div className="App">
      <ApplicationDashboard />
    </div>
  );
}

export default App;


import Airtable from "airtable";
import {TAirtableQueryResults} from 'types/Airtable'

export type Application = {
  id?: string,
  "Company"?: string,
  "Role"?: string,
  "URL"?: string,
  "Origin"?: string,
  "Applied"?: string,
  "Result"?: string,
  "Rejected"?: string,
  "Initial Interview"?: string,
  "Mgr/Team Interview"?: string,
  "Technical Interview"?: string,
  "Code Challenge"?: string,
  "Offer Sent"?: string,
  "Notes"?: string,
  "Source"?: 'Me' | 'Recruiter',
}

const {
  REACT_APP_AIRTABLE_API_KEY: AIRTABLE_API_KEY,
  REACT_APP_AIRTABLE_BASE_ID: AIRTABLE_BASE_ID,
  REACT_APP_AIRTABLE_TABLE_ID: AIRTABLE_TABLE_ID,
} = process.env;

const api = new Airtable({
  apiKey: AIRTABLE_API_KEY
}).base(AIRTABLE_BASE_ID!);

const getApplications = async (): Promise<TAirtableQueryResults> => {
  return new Promise((resolve, reject) => {
    let applicationResults: any[] = [];

    api(AIRTABLE_TABLE_ID!).select({
      view: "Grid view"
    }).eachPage(
      function page(records, fetchNextPage) {
        records.forEach(record => {
          applicationResults.push({
            id: record.id,
            ...record.fields
          });
        });
        fetchNextPage();
      },
      function done(err) {
        if (err) {
          console.error(err);
          reject(returnError(err));
        }
        resolve(returnSuccess(applicationResults));
      }
    );
  });
};

const returnSuccess = (data: any): TAirtableQueryResults => ({
  success: true,
  data,
  error: '',
});

const returnError = (error: any): TAirtableQueryResults => ({
  success: false,
  data: [],
  error,
});

export default getApplications;

import React, { useCallback, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import SVG from 'components/svg';
import type { Application } from 'data/get-applications';
import { useApplications } from 'hooks/use-applications';
import { APPLICATION_STATUS, APPLICATION_STATUS_LABELS } from 'types/ApplicationStatus';
import { getColorForLabel } from 'utils/color';

type ApplicationByStatus = {
  status: APPLICATION_STATUS,
  value: number
}

type ArcData = {
  data: number
  index: number
  value: number
  startAngle: number
  endAngle: number
  padAngle: number
}

const CHART_SIZE = 450;
const INNER_SIZE = 300;
const OUTER_RADIUS = INNER_SIZE / 2;
const INNER_RADIUS = OUTER_RADIUS / 2;

const getFurthestStatus = (app: Application): APPLICATION_STATUS => {
  if (app?.Result) return app.Result as APPLICATION_STATUS;
  if (app?.[APPLICATION_STATUS.Offer]) {
    return APPLICATION_STATUS.Offer;
  }
  if (app?.[APPLICATION_STATUS.Rejected]) {
    return APPLICATION_STATUS.Rejected;
  }
  if (app?.[APPLICATION_STATUS.Code]) {
    return APPLICATION_STATUS.Interviews;
  }
  if (app?.[APPLICATION_STATUS.Technical]) {
    return APPLICATION_STATUS.Interviews;
  }
  if (app?.[APPLICATION_STATUS.MgrTeam]) {
    return APPLICATION_STATUS.MgrTeam;
  }
  if (app?.[APPLICATION_STATUS.Initial]) {
    return APPLICATION_STATUS.Initial;
  }
  if (app?.[APPLICATION_STATUS.Applied]) {
    return APPLICATION_STATUS.Applied;
  }
  return APPLICATION_STATUS.Applied;
};

const LABEL_PLACEMENT_OVERRIDES: Partial<Record<APPLICATION_STATUS, (arg: number) => number>> = {
  [APPLICATION_STATUS.Initial]: (y: number): number => y + 4,
  [APPLICATION_STATUS.Offer]: (y: number): number => y - 2,
}

const ApplicationStatusSlice = ({
  data,
  label,
  color,
  arc,
  outerArc
}: {
  data: ArcData,
  label: APPLICATION_STATUS,
  color: string,
  arc: any,
  outerArc: any
}) => {
  const [ outerArcX, outerArcY ] = outerArc.centroid(data);
  const outerArcCoords = [outerArcX, LABEL_PLACEMENT_OVERRIDES?.[label]?.(outerArcY) ?? outerArcY];
  const linePoints = `${arc.centroid(data)} ${outerArcCoords}`;
  const textAnchor = outerArcCoords[0] > -10 ? 'start' : 'end';
  const dy = (outerArcCoords[1] / OUTER_RADIUS) * 16;
  const textLabel = APPLICATION_STATUS_LABELS?.[label] || label;
  return (
    <>
      <path className="pie-chart__slice" d={arc(data)} fill={getColorForLabel(label)} />
      <polyline points={linePoints} stroke="var(--color-fg, #000)" strokeWidth={2} />
      <text
        className="pie-chart__label"
        textAnchor={textAnchor}
        dy={textLabel === APPLICATION_STATUS.Applied ? dy - 14 : dy}
        transform={`translate(${outerArcCoords})`}
      >
        {textLabel}
      </text>
      {textLabel === APPLICATION_STATUS.Applied && (
        <text
          className="pie-chart__label"
          textAnchor={textAnchor}
          dy={dy}
          transform={`translate(${outerArcCoords})`}
        >
          &amp; Waiting
        </text>
      )}
      <text
        className="pie-chart__label"
        textAnchor={textAnchor}
        dy={dy + 14}
        transform={`translate(${outerArcCoords})`}
      >
        ({data.value})
      </text>
    </>
  );
}

const ApplicationStatus = () => {
  const applications: Application[] = useApplications();
  const chartRef = useRef<SVGSVGElement>(null);

  const filteredApplications = useMemo(() => {
    return applications
      .filter((app: Application) => app?.Applied);
  }, [applications]);


  const applicationsByStatus = useMemo(() => {
    if (!filteredApplications) return [];

    return filteredApplications
      .reduce<ApplicationByStatus[]>((acc: ApplicationByStatus[], app: Application) => {
        const status = getFurthestStatus(app);
        const currStatus = acc.find(s => s.status === status);

        if (currStatus) {
            currStatus.value++;
        } else {
          acc.push({
            status,
            value: 1
          });
        }
        return acc;
      }, []);
  }, [filteredApplications]);

  const pieValues = useMemo(() => {
    return d3.pie().sort(null)(applicationsByStatus.map(s => s.value));
  }, [applicationsByStatus]);

  const createArc = d3.arc()
    .innerRadius(INNER_RADIUS)
    .outerRadius(OUTER_RADIUS);

  const createOuterArc = d3.arc()
    .innerRadius(INNER_RADIUS * 1.5)
    .outerRadius(OUTER_RADIUS * 1.5);

  const getColor = useCallback((status: APPLICATION_STATUS): string => {
    const colors = d3
      .scaleOrdinal()
      .domain(applicationsByStatus.map(s => s.status))
      .range(["ff570a", "4682b4", "9abca7", "87255b", "343633"]);
    return colors(status) as string;
  }, [applicationsByStatus]);

  const getLabel = useCallback((index: number): APPLICATION_STATUS => {
    return applicationsByStatus.at(index)?.status || APPLICATION_STATUS.Applied;
  }, [applicationsByStatus]);

  return (
    <>
      <h2>Applications by Status</h2>
      <SVG ref={chartRef} width={CHART_SIZE} height={CHART_SIZE} shouldRender={!!applications}>
        <g className="data" transform={`translate(${CHART_SIZE / 2}, ${CHART_SIZE / 2})`}>
          <text className="pie-chart__title" dy="8" textAnchor="middle">Total: {filteredApplications.length}</text>
          {pieValues.map((data: any) => {
            const label = getLabel(data.index);
            return (
              <ApplicationStatusSlice
                key={data.index}
                data={data}
                label={label}
                color={getColor(label)}
                arc={createArc}
                outerArc={createOuterArc}
              />
            );
          })}
        </g>
      </SVG>
    </>
  );
}

export default ApplicationStatus;
